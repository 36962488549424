import { memo } from 'react';
import {
  allDigitsRgx,
  bordetella,
  category_description,
  code,
  code_tags_ids,
  codeTagsFilterOperators,
  defaultNumberCompareOperators,
  defaultTextFilterOperators,
  DELETE_KEY_CODES,
  description,
  dhpp,
  emptyOption,
  ENTER_KEY,
  felv,
  free_doses,
  fvrcp,
  group_ids,
  groupsFilterOperatorsServerSide,
  has_transactions,
  historical_times_used,
  influenza,
  is_parasiticide,
  lepto,
  list_price,
  lyme,
  mapped_at,
  mapped_by,
  non_zero_price,
  non_zero_usage,
  not_mapped_by,
  not_revenue_category_id,
  paid_doses,
  PARA_AVG_COLUMN_COLOR,
  PARA_MODE_COLUMN_COLOR,
  pms_code_vetsuccess_id,
  practice_id,
  PSEUDO_EDIT_CLASS,
  quantity_mode,
  quantity_mode_price,
  rabies,
  rattlesnake,
  revenue_category_id,
  review_status,
  times_used,
  total_historical_revenue,
  total_revenue,
  VACCINE_COLUMN_COLOR,
  verification_pipeline_status_id,
  verified,
} from '../../constants/constants';
import TableSetFilter from './filters/TableSetFilter';
import TableTextFilter from './filters/TableTextFilter';
import TableNumberFilter from './filters/TableNumberFilter';
import SingleSelectCellEditor from '../singleSelectCellEditor';
import {
  getFilterSelectElements,
  getMappableElements,
} from '../../helpers/selectHelper';
import MultiselectCellEditor from '../multiselectCellEditor';
import ToggleCellEditor from '../toggleCellEditor';
import TableRadioFilter from './filters/TableRadioFilter';
import TableCheckboxFilter from './filters/TableCheckboxFilter';
import SelectAllHeader from '../mapping/SelectAllHeader';
import TableDateFilter from './filters/TableDateFilter';
import CreatableTableFilter from './filters/CreatableTableFilter.jsx';
import PseudoTextEditor from '../PseudoTextEditor';
import {
  cellValueChanged,
  formatCTValues,
  getValueById,
  noTranslateRenderer,
  parseNameFromStringWithId,
  removeBracketsAndNumbers,
  userRenderer,
  verifiedRenderer,
} from '../../helpers/tableHelper';
import moment from 'moment';
import { ArrowDown } from './tableRenders';

export const getColumnDefinitions = ({
  tableRef,
  practices,
  allRevenueCategories,
  allCodeTags,
  pipelineStatusesForSelect,
  users,
  groups,
  practiceGroupRelationships,
  readOnly,
}) => {
  const headerValue = (field, abreviation, screenSize = 1280) => {
    return tableRef.current.clientWidth < screenSize ? abreviation : field;
  };

  const singleLineCellRenderer = (value) => {
    return (
      <div translate="no" className="flex flex-row">
        <div className="flex-1">{value ? value : '\u2014'}</div>
        {!readOnly && <ArrowDown />}
      </div>
    );
  };

  const multiLineCellRenderer = (params, readOnly = false) => {
    return (
      <div translate="no" className="flex flex-row">
        <div className="flex-1">
          {params.value ? removeBracketsAndNumbers(params.value) : '\u2014'}
        </div>
        {!readOnly && !params.value && <ArrowDown />}
      </div>
    );
  };

  const revenueCategoriesForSelect = getFilterSelectElements(
    getMappableElements(allRevenueCategories)
  );

  return [
    {
      field: '',
      valueFormatter: () => '',
      width: 25,
      maxWidth: 25,
      resizable: false,
      headerComponent: SelectAllHeader,
      suppressFillHandle: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: practice_id.field,
      headerName: practice_id.columnName,
      headerTooltip: practice_id.tooltip,
      headerClass: 'practice-header',
      cellClass: 'practice-cell',
      // tweak to use checkbox empty cell for practice
      cellStyle: {
        position: 'absolute',
        left: '0',
        paddingLeft: '30px',
        width: '90px',
      },
      width: 65,
      suppressFillHandle: true,
      filter: TableSetFilter,
      filterParams: {
        options: practices,
        async: true,
      },
    },
    {
      field: group_ids.field,
      headerName: group_ids.columnName,
      headerTooltip: group_ids.tooltip,
      valueGetter: (params) =>
        practiceGroupRelationships[params.data[practice_id.field]] || [],
      width: 75,
      suppressFillHandle: true,
      hide: true,
      sortable: false,
      filter: TableSetFilter,
      filterParams: {
        options: groups,
        operators: groupsFilterOperatorsServerSide,
      },
    },
    {
      field: pms_code_vetsuccess_id.field,
      headerName: pms_code_vetsuccess_id.columnName,
      headerTooltip: pms_code_vetsuccess_id.columnName,
      headerValueGetter: (params) =>
        headerValue(
          params.colDef.headerName,
          pms_code_vetsuccess_id.abbreviation,
          1500
        ),
      autoHeight: true,
      width: 100,
      suppressFillHandle: true,
      hide: true,
      filter: CreatableTableFilter,
    },
    {
      field: code.field,
      headerName: code.columnName,
      headerTooltip: code.columnName,
      suppressFillHandle: true,
      width: 60,
      filter: TableTextFilter,
      filterParams: {
        maxTextLength: 10,
        operators: defaultTextFilterOperators,
      },
    },
    {
      field: times_used.field,
      headerName: times_used.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, times_used.abbreviation, 1500),
      headerTooltip: times_used.columnName,
      suppressFillHandle: true,
      width: 60,
      filter: false,
      sortable: false,
    },
    {
      field: total_revenue.field,
      headerName: total_revenue.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, total_revenue.abbreviation),
      valueFormatter: (params) => `$${params.value}`,
      headerTooltip: total_revenue.columnName,
      width: 70,
      suppressFillHandle: true,
      filter: TableNumberFilter,
      filterParams: {
        operators: defaultNumberCompareOperators,
      },
      sortable: false,
    },
    {
      field: list_price.field,
      headerName: list_price.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, list_price.abbreviation),
      valueFormatter: (params) => `$${params.value}`,
      headerTooltip: list_price.columnName,
      suppressFillHandle: true,
      width: 60,
      filter: false,
      sortable: false,
    },
    {
      field: category_description.field,
      headerName: category_description.columnName,
      headerValueGetter: (params) =>
        headerValue(
          params.colDef.headerName,
          category_description.abbreviation
        ),
      headerTooltip: category_description.columnName,
      autoHeight: true,
      width: 140,
      suppressFillHandle: true,
      filter: TableTextFilter,
      filterParams: {
        maxTextLength: 10,
        operators: defaultTextFilterOperators,
      },
      editable: true,
      singleClickEdit: false,
      cellEditor: PseudoTextEditor,
      cellClass: PSEUDO_EDIT_CLASS,
      //prevent changing value in column by pasting
      valueSetter: () => false,
    },
    {
      field: description.field,
      headerName: description.columnName,
      autoHeight: true,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, description.abbreviation),
      headerTooltip: description.columnName,
      width: 150,
      suppressFillHandle: true,
      filter: TableTextFilter,
      filterParams: {
        maxTextLength: 10,
        operators: defaultTextFilterOperators,
      },
      editable: true,
      singleClickEdit: false,
      cellEditor: PseudoTextEditor,
      cellClass: PSEUDO_EDIT_CLASS,
      //prevent changing value in column by pasting
      valueSetter: () => false,
    },
    {
      field: 'parasiticides_average',
      headerName: 'Average Sales Excluding Free Transactions',
      headerTooltip: 'Average Sales Excluding Free Transactions',
      children: [
        {
          field: non_zero_usage.field,
          columnGroupShow: 'open',
          width: 85,
          headerName: non_zero_usage.columnName,
          headerTooltip: non_zero_usage.columnName,
          cellStyle: { backgroundColor: PARA_AVG_COLUMN_COLOR },
          valueGetter: (params) =>
            params.data[non_zero_usage.field][non_zero_usage.nested_field],
          valueFormatter: (params) =>
            params.value ? `${params.value}%` : '\u2014',
          hide: true,
          sortable: false,
          filter: false,
        },
        {
          field: non_zero_price.field,
          columnGroupShow: 'open',
          width: 85,
          headerName: non_zero_price.columnName,
          headerTooltip: non_zero_price.columnName,
          cellStyle: { backgroundColor: PARA_AVG_COLUMN_COLOR },
          valueGetter: (params) =>
            params.data[non_zero_price.field][non_zero_price.nested_field],
          valueFormatter: (params) =>
            params.value ? `$${params.value}` : '\u2014',
          hide: true,
          sortable: false,
          filter: false,
        },
      ],
    },
    {
      field: 'parasiticide_mode',
      headerName: 'Mode',
      headerTooltip: 'Mode',
      children: [
        {
          field: quantity_mode.field,
          columnGroupShow: 'open',
          width: 85,
          headerName: quantity_mode.columnName,
          headerTooltip: quantity_mode.columnName,
          cellStyle: { backgroundColor: PARA_MODE_COLUMN_COLOR },
          valueGetter: (params) =>
            params.data[quantity_mode.field][quantity_mode.nested_field],
          hide: true,
          sortable: false,
          filter: false,
        },
        {
          field: quantity_mode_price.field,
          columnGroupShow: 'open',
          width: 95,
          headerName: quantity_mode_price.columnName,
          headerTooltip: quantity_mode_price.columnName,
          cellStyle: { backgroundColor: PARA_MODE_COLUMN_COLOR },
          valueGetter: (params) =>
            params.data[quantity_mode_price.field][
              quantity_mode_price.nested_field
            ],
          valueFormatter: (params) =>
            params.value ? `$${params.value}` : '\u2014',
          hide: true,
          sortable: false,
          filter: false,
        },
      ],
    },
    {
      field: 'vacc_para_data',
      headerName: 'Vaccine Frequency',
      children: [
        {
          field: dhpp.field,
          columnGroupShow: 'open',
          width: 80,
          headerName: dhpp.columnName,
          headerTooltip: dhpp.columnName,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          valueGetter: (params) => params.data[dhpp.field][dhpp.nested_field],
          hide: true,
          sortable: false,
          filter: false,
        },
        {
          field: rabies.field,
          columnGroupShow: 'open',
          width: 80,
          headerName: rabies.columnName,
          headerTooltip: rabies.columnName,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          valueGetter: (params) =>
            params.data[rabies.field][rabies.nested_field],
          hide: true,
          sortable: false,
          filter: false,
        },
        {
          field: lepto.field,
          columnGroupShow: 'open',
          width: 80,
          headerName: lepto.columnName,
          headerTooltip: lepto.columnName,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          valueGetter: (params) => params.data[lepto.field][lepto.nested_field],
          hide: true,
          sortable: false,
          filter: false,
        },
        {
          field: lyme.field,
          columnGroupShow: 'open',
          width: 80,
          headerName: lyme.columnName,
          headerTooltip: lyme.columnName,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          valueGetter: (params) => params.data[lyme.field][lyme.nested_field],
          hide: true,
          sortable: false,
          filter: false,
        },
        {
          field: bordetella.field,
          columnGroupShow: 'open',
          width: 80,
          headerName: bordetella.columnName,
          headerTooltip: bordetella.columnName,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          valueGetter: (params) =>
            params.data[bordetella.field][bordetella.nested_field],
          hide: true,
          sortable: false,
          filter: false,
        },
        {
          field: fvrcp.field,
          columnGroupShow: 'open',
          width: 80,
          headerName: fvrcp.columnName,
          headerTooltip: fvrcp.columnName,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          valueGetter: (params) => params.data[fvrcp.field][fvrcp.nested_field],
          hide: true,
          sortable: false,
          filter: false,
        },
        {
          field: felv.field,
          columnGroupShow: 'open',
          width: 80,
          headerName: felv.columnName,
          headerTooltip: felv.columnName,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          valueGetter: (params) => params.data[felv.field][felv.nested_field],
          hide: true,
          sortable: false,
          filter: false,
        },
        {
          field: rattlesnake.field,
          columnGroupShow: 'open',
          width: 80,
          headerName: rattlesnake.columnName,
          headerTooltip: rattlesnake.columnName,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          valueGetter: (params) =>
            params.data[rattlesnake.field][rattlesnake.nested_field],
          hide: true,
          sortable: false,
          filter: false,
        },
        {
          field: influenza.field,
          columnGroupShow: 'open',
          width: 80,
          headerName: influenza.columnName,
          headerTooltip: influenza.columnName,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          valueGetter: (params) =>
            params.data[influenza.field][influenza.nested_field],
          hide: true,
          sortable: false,
          filter: false,
        },
      ],
    },
    {
      field: revenue_category_id.field,
      headerName: revenue_category_id.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, revenue_category_id.abbreviation),
      cellRenderer: memo((params) => {
        let value = null;
        if (params.value) {
          value =
            typeof params.value === 'number' || allDigitsRgx.test(params.value)
              ? getValueById(allRevenueCategories, params.value)
              : parseNameFromStringWithId(params.value);
        }
        return singleLineCellRenderer(value, readOnly);
      }),
      cellClass: !readOnly && 'editable-cell',
      autoHeight: true,
      headerTooltip: revenue_category_id.columnName,
      width: 140,
      editable: !readOnly,
      cellEditor: SingleSelectCellEditor,
      cellEditorParams: {
        options: allRevenueCategories,
        isSelectable: (o) => o.mappable,
        onKeyDown: () => undefined,
        isSearchable: true,
      },
      cellEditorPopup: true,
      filter: TableSetFilter,
      filterParams: {
        options: revenueCategoriesForSelect,
      },
      valueGetter: (params) => {
        if (params.data.revenue_category_id) {
          let category = allRevenueCategories.find(
            (rc) => rc.id === params.data.revenue_category_id
          );
          return category
            ? `${category.value}[${category.id}]`
            : params.data.revenue_category_id;
        } else return null;
      },
    },
    {
      field: code_tags_ids.field,
      headerName: code_tags_ids.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, code_tags_ids.abbreviation),
      cellRenderer: memo(multiLineCellRenderer),
      headerTooltip: code_tags_ids.columnName,
      cellClass: !readOnly && 'editable-cell',
      width: 100,
      editable: !readOnly,
      autoHeight: true,
      suppressKeyboardEvent: (params) => {
        // while editing cell, disable 'Enter'
        // suppress delete so values are not removed by ag grid and we don't lose values in case server errors
        return (
          (params.editing && params.event.key === ENTER_KEY) ||
          DELETE_KEY_CODES.includes(params.event.keyCode)
        );
      },
      cellEditor: MultiselectCellEditor,
      cellEditorParams: {
        values: allCodeTags,
        selectableValues: getMappableElements(allCodeTags),
      },
      cellEditorPopup: true,
      filter: TableSetFilter,
      filterParams: {
        options: getFilterSelectElements(getMappableElements(allCodeTags)),
        operators: codeTagsFilterOperators,
      },
      sortable: false,
      valueSetter: (params) => {
        //skip setting if setting from empty to empty - don't put to undo stack
        if (!cellValueChanged(params.oldValue, params.newValue)) {
          return false;
        } else {
          //if this is not set newValue and oldValue will be same and update in onCellValueChanged will not work correctly
          params.data[code_tags_ids.field] = params.newValue;
          return true;
        }
      },
      valueGetter: (params) => {
        if (params.data.code_tag_ids && params.data.code_tag_ids.length > 0) {
          if (Array.isArray(params.data.code_tag_ids)) {
            return formatCTValues(
              getMappableElements(allCodeTags),
              params.data.code_tag_ids,
              (ct) => `${ct.value}[${ct.id}]`
            );
          } else return params.data.code_tag_ids;
        } else return null;
      },
    },
    {
      field: paid_doses.field,
      headerName: paid_doses.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, paid_doses.abbreviation),
      editable: !readOnly,
      cellEditor: 'agTextCellEditor',
      cellRenderer: memo(noTranslateRenderer),
      headerTooltip: paid_doses.columnName,
      cellClass: !readOnly && 'editable-cell',
      maxWidth: 70,
      width: 67,
      valueSetter: (params) => {
        let newValue = Array.isArray(params.newValue)
          ? params.newValue[0]
          : params.newValue;
        if (!newValue) {
          params.data[paid_doses.field] = 0;
          return true;
        } else if (Number.isInteger(parseInt(newValue))) {
          params.data[paid_doses.field] = parseInt(newValue);
          return true;
        } else {
          return false;
        }
      },
      filter: TableNumberFilter,
      filterParams: {
        operators: [{ value: 'eq', label: 'Equals' }],
      },
    },
    {
      field: free_doses.field,
      headerName: free_doses.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, free_doses.abbreviation),
      editable: !readOnly,
      cellEditor: 'agTextCellEditor',
      headerTooltip: free_doses.columnName,
      cellClass: !readOnly && 'editable-cell',
      cellRenderer: memo(noTranslateRenderer),
      maxWidth: 70,
      width: 67,
      valueSetter: (params) => {
        //handle fill handle
        let newValue = Array.isArray(params.newValue)
          ? params.newValue[0]
          : params.newValue;
        if (!newValue) {
          params.data[free_doses.field] = 0;
          return true;
        } else if (Number.isInteger(parseInt(newValue))) {
          params.data[free_doses.field] = parseInt(newValue);
          return true;
        } else {
          return false;
        }
      },
      filter: TableNumberFilter,
      filterParams: {
        operators: [{ value: 'eq', label: 'Equals' }],
      },
    },
    {
      field: verified.field,
      headerName: verified.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, verified.abbreviation),
      headerTooltip: verified.columnName,
      cellRenderer: memo(verifiedRenderer),
      cellClass: !readOnly && 'editable-cell',
      editable: !readOnly,
      width: 60,
      maxWidth: 75,
      cellEditor: ToggleCellEditor,
      valueSetter: (params) => {
        if (verified.values.includes(params.newValue)) {
          params.data[verified.field] = [true, 'true'].includes(
            params.newValue
          );
          return true;
        } else {
          return false;
        }
      },
      filter: TableRadioFilter,
      filterParams: { defaultValue: 'No' },
    },
    {
      field: review_status.field,
      headerName: review_status.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, review_status.abbreviation),
      cellRenderer: memo((params) => {
        let value = review_status.values_by_key[params.value];
        return singleLineCellRenderer(value ? value : null);
      }),
      headerTooltip: review_status.columnName,
      cellClass: !readOnly && 'editable-cell',
      width: 100,
      editable: !readOnly,
      cellEditor: SingleSelectCellEditor,
      cellEditorParams: {
        options: review_status.forSelect,
        onKeyDown: () => undefined,
      },
      cellEditorPopup: true,
      filter: TableCheckboxFilter,
      filterParams: {
        options: review_status.filterValues,
      },
      valueSetter: (params) => {
        let value = params.newValue;
        //skip setting if setting from empty to empty - don't put to undo stack (initial is undefined and after selecting is null)
        if (
          review_status.emptyMappingValues.includes(params.newValue) &&
          review_status.emptyMappingValues.includes(params.oldValue)
        ) {
          return false;
        } else if (
          Object.keys(review_status.values_by_key).includes(value) ||
          Object.values(review_status.values_by_key).includes(value)
        ) {
          params.data[review_status.field] = review_status.forSelect.find(
            (rs) => rs.label === value || rs.id === value
          ).id;
          return true;
        } else if (review_status.emptyMappingValues.includes(value)) {
          //emptyMappingValues holds all of the 'null' values. So by pasting '-' or '', we will map field to null, like when inline editing
          params.data[review_status.field] = null;
          return true;
        } else return false;
      },
    },
    {
      field: verification_pipeline_status_id.field,
      headerName: verification_pipeline_status_id.columnName,
      headerValueGetter: (params) =>
        headerValue(
          params.colDef.headerName,
          verification_pipeline_status_id.abbreviation
        ),
      cellRenderer: (params) => {
        let pipelineStatus = null;
        if (params.value) {
          pipelineStatus =
            getValueById(pipelineStatusesForSelect, params.value) ===
            params.value
              ? 'deleted status'
              : getValueById(pipelineStatusesForSelect, params.value);
        }
        return singleLineCellRenderer(pipelineStatus, readOnly);
      },
      headerTooltip: verification_pipeline_status_id.columnName,
      cellClass: !readOnly && 'editable-cell',
      width: 120,
      editable: !readOnly,
      autoHeight: true,
      cellEditor: SingleSelectCellEditor,
      cellEditorParams: {
        options: [emptyOption].concat(pipelineStatusesForSelect),
        onKeyDown: () => undefined,
        isSearchable: true,
      },
      cellEditorPopup: true,
      filter: false,
      sortable: false,
    },
    {
      field: mapped_by.field,
      headerName: mapped_by.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, mapped_by.abbreviation),
      cellRenderer: (params) => {
        if (readOnly) return params.value;
        return userRenderer(users, params.value);
      },
      headerTooltip: mapped_by.columnName,
      width: 110,
      autoHeight: true,
      suppressFillHandle: true,
      filter: TableSetFilter,
      filterParams: {
        options: users,
      },
    },
    {
      field: is_parasiticide.field,
      headerName: is_parasiticide.columnName,
      hide: true,
      suppressColumnsToolPanel: true,
      filter: TableRadioFilter,
    },
    {
      field: mapped_at.field,
      headerName: mapped_at.columnName,
      hide: true,
      filter: TableDateFilter,
      suppressFillHandle: true,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format('YYYY-MM-DD') : '\u2014',
      width: 100,
    },
    {
      field: has_transactions.field,
      headerName: has_transactions.columnName,
      hide: true,
      suppressColumnsToolPanel: true,
      filter: TableRadioFilter,
      filterParams: { defaultValue: 'Yes' },
    },
    {
      field: not_mapped_by.field,
      headerName: not_mapped_by.columnName,
      hide: true,
      suppressColumnsToolPanel: true,
      filter: TableSetFilter,
      filterParams: {
        options: users,
      },
    },
    {
      field: not_revenue_category_id.field,
      headerName: not_revenue_category_id.columnName,
      headerValueGetter: (params) =>
        headerValue(
          params.colDef.headerName,
          not_revenue_category_id.abbreviation
        ),
      hide: true,
      suppressColumnsToolPanel: true,
      filter: TableSetFilter,
      filterParams: {
        options: revenueCategoriesForSelect,
      },
    },
    {
      field: historical_times_used.field,
      headerName: historical_times_used.columnName,
      headerValueGetter: (params) =>
        headerValue(
          params.colDef.headerName,
          historical_times_used.abbreviation
        ),
      headerTooltip: historical_times_used.columnName,
      width: 85,
      hide: true,
      suppressFillHandle: true,
      filter: false,
      sortable: false,
    },
    {
      field: total_historical_revenue.field,
      headerName: total_historical_revenue.columnName,
      headerValueGetter: (params) =>
        headerValue(
          params.colDef.headerName,
          total_historical_revenue.abbreviation
        ),
      headerTooltip: total_historical_revenue.columnName,
      valueFormatter: (params) => `$${params.value}`,
      width: 85,
      hide: true,
      suppressFillHandle: true,
      filter: TableNumberFilter,
      filterParams: {
        values: defaultNumberCompareOperators,
      },
      sortable: false,
    },
  ];
};

export const defaultColumnDefinition = (readOnly, onFilterModified) => {
  return {
    wrapText: true,
    width: 60,
    filterParams: { onModelModify: onFilterModified },
    singleClickEdit: true,
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
    suppressFillHandle: readOnly,
    valueFormatter: (params) =>
      params.value != null && params.value !== 0 ? params.value : '\u2014',
    suppressKeyboardEvent: (params) => {
      //suppress delete so values are not removed by ag grid and we don't lose values in case server errors
      return DELETE_KEY_CODES.includes(params.event.keyCode);
    },
    headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
          <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
          <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
            <span data-ref="eText" class="ag-header-cell-text" role="columnheader"></span>
            <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
          <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
           <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
          <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
            <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
          </div>
        </div>`,
    },
    suppressHeaderKeyboardEvent: (params) => {
      // We need to explicitly call onCellKeyDown event, because it is not being called when header is in focus.
      params.api.eventService.gos.gridOptions.onCellKeyDown(params);
    },
  };
};
